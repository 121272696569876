import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";

function About() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    // autoplaySpeed: 10000000000,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleToggler = (event) => {
    setToggler({
      toggler: event,
    });
  };

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    axios.get("/api/services").then((response) => {
      setServices(response.data);
    });
    axios.get("/api/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <img
                  src={information.aboutImage}
                  alt="about"
                  onClick={() => handleToggler(!toggler)}
                />
                <span className="mi-about-image-icon">
                  {/* <Icon.ZoomIn /> */}
                </span>
                {/* <FsLightbox
                  toggler={toggler}
                  sources={[information.aboutImageLg]}
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3>
                <p>
                I didn’t decide I wanted to be a web developer.
                <br></br>
                <br></br>
                  After leaving health care and going back to school for Database Administration and  Development I ended up taking an advanced JavaScript course where we were to build an SPA MERN CRUD. I basically had no idea what I was doing and worked harder at that class than any other I have taken. Once I finished my studies I could not stop thinking about that class, and even though I was trying to get a job as a Jr. DBA I just found myself going back to web dev. I couldn’t resist the calling as I was officially obsessed, and since then I have either been making web pages and web apps. 
                  <br></br>
                <br></br>
                  The web pages I make with WordPress, or HTML, CSS, and JavaScript, either from the ground up or with templates. The web apps are in the MERN stack with me doing all the design, front and back end, deploying etc. All my apps, sans a Pokedex, we just me diving head first into it and learning as I go. 

                After all the learning I was finally able to snag my first job at Akerna as a software engineer. To say I love it would be an understatement. At the end of the day I have to tell myself to stop, because if I don't, I will just keep going and lose track of time.
                <br></br>
                <br></br>
                Like I said, it wasn't a choice. Development just won't leave my brain, but that's OK because I love it.
               
                </p>
                <ul>
                  {/* {!information.name ? null : (
                    <li>
                      <b>Full Name</b> {information.name}
                    </li>
                  )} */}
                  {/* {!information.age ? null : <li>
                    <b>Age</b> {information.age} Years
                  </li>} */}
                  {!information.phone ? null : (
                    <li>
                      <b>Phone</b> {information.phone}
                    </li>
                  )}
                  {/* {!information.nationality ? null : <li>
                    <b>Nationality</b> {information.nationality}
                  </li>} */}
                  {/* {!information.language ? null : <li>
                    <b>Languages</b> {information.language}
                  </li>} */}
                  {!information.email ? null : (
                    <li>
                      <b>Email</b> {information.email}
                    </li>
                  )}
                  {!information.address ? null : (
                    <li>
                      <b>Location</b> {information.address}
                    </li>
                  )}
                  {!information.freelanceStatus ? null : (
                    <li>
                      <b>Freelance</b> {information.freelanceStatus}
                    </li>
                  )}
                </ul>
                <a href={information.cvfile} className="mi-button">
                  Download Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Services" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map((service) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  key={service.title}
                >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Reviews" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {reviews.map((review) => (
                  <Testimonial key={review.id} content={review} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
