import React, { useState, useEffect } from "react";
import axios from 'axios';
import TrackVisibility from "react-on-screen";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from '../components/Smalltitle';
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";

function FunFacts(){
//   const [skills, setSkills] = useState([]);
//   const [workingExperience, setWorkingExperience] = useState([]);
//   const [educationExperience, setEducationExperience] = useState([]);
const [information, setInformation] = useState("");
  useEffect(() =>{
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
   
  }, [])

  return (
    <Layout>
      <div className="mi-funfacts-area ">
      <div className="container">
        
          <Sectiontitle title="Other interests and fun facts" />
          <h3>Just a little about me, the person</h3>
          <p>
          I’ve been married several years and it is great, I love my wife, she is awesome.
          </p>
          <figure>
          <div className="mi-funfacts-image">
                <img
                //  class="small"
                  src={information.laura}
                  alt="Laura and I"
                  
                />
                </div>
                <figcaption>My wife and I on our anniversary</figcaption>
              
              </figure>
              <br></br>
<p>
When not working and/or coding I like to fish for bass while listening to audiobooks or podcasts. Typically the audiobooks are from Stephen King as I am a big fan (my favorite book is ‘It’) and he is quite prolific. Fortunately the Seattle Public Library has audiobooks I can get on my phone, it is a true game changer. Speaking of podcasts I do have my own that I do with my friend (and guests) about Arnold Schwarzenegger movies called “See You At The Poddy, Richter!”. We are by no means film critics so it is much more on the comedy side, and it is a lot of fun.
</p><p>
  
<div className="mi-funfacts-row">
<div className="mi-funfacts-column">
<figure>
          <div className="mi-funfacts-image">
           
                <img
                //  class="small"
                  src={information.fishing}
                  alt="Joseph with a bass"
                  
                />
                </div>
                <figcaption>Amazing picture of me with a bass</figcaption>
              
              </figure>
              </div>
              <div className="mi-funfacts-column">
              <figure>
          <div className="mi-funfacts-image">
                <img
                //  class="small"
                  src={information.eraser_poster}
                  alt="Joseph and Aaron's faces photoshopped over the movie Eraser"
                  
                />
                </div>
                <figcaption>Movie poster for our Eraser episode</figcaption>
              
              </figure>
              </div>
              </div>
              


</p><p>

              
I also enjoy sports, mainly the NFL and NBA, with my teams being The Raiders and Lakers. I like to think my wife knew I was the one once she saw how loyal I was, with that directed at the Raiders even though they had been, well, garbage for over a decade and were in the midst of another awful season (only 1 of the past 18 seasons did they have a winning record, and the hope was already dashed when the QB broke his leg in the second to the last game. I had just bought a new TV that day and got to see in beautiful 4K any Super Bowl hopes die). Fortunately I have the Lakers keeping me sane, but from 2011-2019 it was pretty brutal for me as a sports fan. I don’t play much sports anymore because last time I played hoops I managed to break both my arms and a foot, had to have 2 slings and a walking boot. It actually was pretty hilarious. I still enjoy cycling however.
</p><p>
<div className="mi-funfacts-row">
<div className="mi-funfacts-column">
<figure>
<div className="mi-funfacts-image">
                <img
                //  class="small"
                  src={information.kobe}
                  alt="Joseph With Kobe Bryant"
                  
                />
                </div>
                <figcaption>I snagged a pic with Kobe Bryant in one of his last games</figcaption>
              
              </figure>
              
              </div>
              <div className="mi-funfacts-column">
<figure>
<div className="mi-funfacts-image">
                <img
                //  class="small"
                  src={information.broken}
                  alt="Joseph with 3 broken limbs"
                  
                />
                </div>
                <figcaption>This was my life for a few months</figcaption>
              
              </figure>
              
              </div>
              </div>
              <br></br>
I do enjoy playing non sports games though, whether it be of the video, role playing, or  of the parlor sort. My favorite video games are Fallout: New Vegas (where I built a PC just to play the modded version of NV) and ToeJam and Earl. I just bought a Switch and started playing Rocket League and that game is scaringly addictive. I have been playing Dungeons and Dragons with my friends online since March 2020 and it is ridiculous, awesome, and a great excuse to hang out virtually. I used to be really into poker, even making it as a pro and a semi pro for a while. Poker is beatable as it is the one game in the casino where you are going against other players and not the house, which is why it was the only game I played there. For over a decade it was what I absolutely loved doing as it is a lot of problem solving and constant learning, plus I’m really competitive. Once I discovered programming though poker just left my mind completely, and now I can’t stop thinking about programming. 
</p><p>
My favorite shows are The Simpsons and The Wire. The Simpson basically shaped my view on the world and my sense of humor, and I think The Wire is the best piece of art put on the small screen. My favorite movies are The Terminator and Terminator 2 (thus the Arnold podcast). I am in the firm belief that the greatest album to listen to while coding is “Computer World” by Kraftwerk (thus my logo). I love Kraftwerk and also The Beatles, Talking Heads, Tv on the Radio, a lot of 90s rap/hip-hop, 60s/70s MoTown, a bunch of other stuff, and probably way too much “Weird Al” Yankovic for a man of my age (nah, there’s never enough). Below is one of my favorite videos, it is a mash up of The Wire starting credits with scenes from The Simpsons.
<br></br>
</p>
<div className="mi-funfacts-video">
<iframe width="560" height="315" src="https://www.youtube.com/embed/Tf-QPqlz0Kw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<br></br>
<br></br>
"Of course your site has to have something Simpsons!"
<br></br>
-My mom upon seeing this page
</div>
<br></br>

          </div>
      </div>
     
    </Layout>
  );
}

export default FunFacts;
